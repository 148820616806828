import { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'

import { useBoolean } from 'usehooks-ts'
import { trackEvent } from 'shared/services/analytics/google'
import signOutUser from 'views/authentication/services/signOutUser'
import useOnClickOutside from 'hooks/useOnClickOutside'
import Router from 'next/router'
import { useAskingRoleModals } from 'shared/hooks/useAskingRoleModals'
import { resetUsetiful } from 'shared/services/analytics/usetiful'
import SpinnerIcon from 'shared/icons/SpinnerIcon'
import { resetSegmentUser, trackSegmentCustomEvent } from 'shared/services/analytics/segment'

type LogoutModalContextType = {
  showLogoutModal: () => void
}

const LogoutModalContext = createContext<LogoutModalContextType>({} as LogoutModalContextType)

type LogoutModalProviderProps = {
  children: ReactNode
}

export const LogoutModalProvider = ({ children }: LogoutModalProviderProps) => {
  const { value: logoutModal, setTrue: showLogoutModal, setFalse: hideLogoutModal } = useBoolean(false)
  const { setIsAskingRoleBannerClosed } = useAskingRoleModals()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const logoutRef = useRef<HTMLDivElement>(null)

  // Handlers
  const setOnClickOutsideLogout = useCallback(() => {
    hideLogoutModal()
  }, [hideLogoutModal])

  const handleLogOut = useCallback(async () => {
    try {
      setIsLoggingOut(true)
      signOutUser()
      trackEvent({ action: 'log_out' })
      trackSegmentCustomEvent('log_out')
      setIsAskingRoleBannerClosed(false)
      resetUsetiful()
      resetSegmentUser()
      // could be removed in the future if we have user URL link, or fixed username
    } catch (e) {
      console.error(e)
    } finally {
      hideLogoutModal()
      Router.push('/login')
      localStorage.removeItem('uploading-artwork-tip-closed') // clear history of uploading artwork tooltip
      setIsLoggingOut(false)
    }
  }, [hideLogoutModal, setIsAskingRoleBannerClosed])

  // Hooks
  useOnClickOutside({ ref: logoutRef, handler: setOnClickOutsideLogout })

  // Context
  const contextValue = useMemo(() => {
    return {
      showLogoutModal,
    }
  }, [showLogoutModal])

  return (
    <LogoutModalContext.Provider value={contextValue}>
      {children}
      {logoutModal && (
        <div className="fixed top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
          <div
            className="flex h-[250px] w-[350px] flex-col justify-between rounded-[24px] bg-white p-8"
            ref={logoutRef}
          >
            <h1>Are you sure you want to Log Out?</h1>
            <div className="flex w-full flex-col space-y-2">
              <button
                className="rounded-full border border-black bg-mint-green py-2 uppercase"
                onClick={handleLogOut}
                disabled={isLoggingOut}
              >
                {isLoggingOut ? <SpinnerIcon className="text-lg" /> : 'Log Out'}
              </button>
              <button className="py-2" onClick={hideLogoutModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </LogoutModalContext.Provider>
  )
}

export const useLogoutModal = () => {
  const context = useContext(LogoutModalContext)
  return context
}
