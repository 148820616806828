import { atom } from 'jotai'

// @shared - types
import { ConversationType, ActiveConversationType, ConversationUserType, ConversationArtworkType } from '@shared/types'

export const ConversationsState = atom<ConversationType[]>([])

export const TotalUnreadMessageState = atom<number>(0)

export const ActiveConversationState = atom<ActiveConversationType | null>(null)

export const ConversationUsersState = atom<Record<number, ConversationUserType>>({})

export const ConversationArtworksState = atom<Record<number, ConversationArtworkType>>({})
