// @ts-nocheck

import { useAtom } from 'jotai'
import { produce } from 'immer'

// @shared - states
import { TablePaginationState } from '@shared/states/TableState'
// @shared - utils
import { createUseQuery } from '@shared/utils/reactQuery'

// standardize use load more query hook for CSR and SSR
export const createUseLoadMoreQuery = ({ queryKey: baseQueryKey, queryFn, setStateCallback = () => {} }) => {
  // create new use load more query hook
  const useLoadMoreQuery = ({
    defaultPage = 0,
    defaultPageSize,
    queryParams = {},
    onSuccess,
    queryKey: customQueryKey,
    ...useQueryOptions
  } = {}) => {
    const queryKey = customQueryKey ?? baseQueryKey

    // -- internal state --
    const [pagination, setPagination] = useAtom(
      TablePaginationState({ id: JSON.stringify(queryKey), defaultPage, defaultPageSize }),
    )
    const { page, pageSize, total } = pagination

    // query
    const useQuery = createUseQuery({
      queryKey,
      queryFn,
      setStateCallback,
    })
    const query = useQuery({
      queryParams: { page: page + 1, pageSize, ...queryParams },
      onSuccess: (res) => {
        // update pagination based on response
        setPagination(
          produce((draft) => {
            draft.page = res.data.page
            draft.pageSize = res.data.pageSize
            draft.total = res.data.total
          }),
        )
        // call `onSuccess`
        onSuccess?.(res)
      },
      ...useQueryOptions,
    })

    const hasNext = total > page * pageSize
    const fetchNext = () => {
      if (!hasNext || query.isFetching) return
      // make request
      query.refetch()
    }

    return { ...query, hasNext, fetchNext, page, pageSize, total }
  }

  useLoadMoreQuery.queryFn = queryFn
  useLoadMoreQuery.setStateCallback = setStateCallback

  return useLoadMoreQuery
}

export default createUseLoadMoreQuery
