import '@splidejs/react-splide/css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'simplebar-react/dist/simplebar.min.css'
import 'styles/global.scss'

import Head from 'next/head'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import NiceModal from '@ebay/nice-modal-react'

import { IS_UNDER_CONSTRUCTION, GOOGLE_TAG_MANAGER_CONTAINER_ID, IS_DEVELOPMENT, GOOGLE_ANALYTICS_GA4 } from 'configs'
import { AppPropsWithPageOptions } from 'shared/types/App'
import { ConfirmModalProvider } from 'hooks/common/useConfirmModal'
import { FC } from 'react'
import { LogoutModalProvider } from 'hooks/common/useLogoutModal'
import { AskingRoleModalsProvider } from 'shared/hooks/useAskingRoleModals'
import { CustomRouterProvider } from 'contexts/CustomRouterContext'
import { SWRConfig } from 'swr'
import { AppSettingsProvider } from 'contexts/AppSettingsContext'
import { AppLayoutProvider } from 'contexts/AppLayoutContext'
import { AuthProvider } from 'contexts/AuthContext'
import { isHumanUserBrowswer } from 'utils/common'
import InitialSetupController from 'shared/components/app/InitialSetupController'

// @shared - providers
import AppProviders from '@shared/components/Providers'

const TopProgressBar = dynamic(() => import('shared/components/loader/TopProgressBar'), { ssr: false })
const ToastifySetup = dynamic(() => import('shared/components/ToastifySetup'), { ssr: false })
const UnderConstruction = dynamic(() => import('shared/components/layout/UnderConstruction'), { ssr: false })

// NextJS App Component to initialize the pages
const MyApp = ({ Component, pageProps }: AppPropsWithPageOptions) => {
  if (IS_UNDER_CONSTRUCTION) {
    return (
      <div className="relative h-screen w-screen">
        <UnderConstruction />
      </div>
    )
  }

  return (
    <>
      <AppProviders>
        <AuthProvider>
          <CustomRouterProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
              <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
            </Head>
            <InitialSetupController />
            <TopProgressBar />
            <AppSettingsProvider>
              <ConfirmModalProvider>
                <AskingRoleModalsProvider>
                  <LogoutModalProvider>
                    <AppLayoutProvider>
                      <NiceModal.Provider>
                        <Component {...pageProps} />
                      </NiceModal.Provider>
                    </AppLayoutProvider>
                  </LogoutModalProvider>
                </AskingRoleModalsProvider>
              </ConfirmModalProvider>
            </AppSettingsProvider>

            <ToastifySetup />

            {!IS_DEVELOPMENT && isHumanUserBrowswer() && (
              <>
                <Script src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_GA4}`} />
                <Script
                  id="google-analytics-script"
                  dangerouslySetInnerHTML={{
                    __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('consent', 'default', {
                'analytics_storage': 'denied'
              });
              gtag('config', '${GOOGLE_ANALYTICS_GA4}', { send_page_view: true });
            `,
                  }}
                />
              </>
            )}

            {isHumanUserBrowswer() && (
              <Script
                strategy="lazyOnload"
                id="google-tag-manager"
                dangerouslySetInnerHTML={{
                  __html: `
                  (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER_ID}');
                `,
                }}
              />
            )}
          </CustomRouterProvider>
        </AuthProvider>
      </AppProviders>
    </>
  )
}

function withSWRGlobal<P>(component: FC<P>) {
  const AppWithSWR: FC<P> = (props) => (
    <SWRConfig
      value={{
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Only retry up to 5 times.
          if (retryCount >= 5) return
          // retry after 5 seconds
          setTimeout(() => revalidate({ retryCount }), 5000)
        },
      }}
    >
      {component(props)}
    </SWRConfig>
  )

  return AppWithSWR
}

export default withSWRGlobal(MyApp)
